import { SimpleDataTable } from "../../components/globals/newDataTable";
import { tableHeaders } from "../../data/tableHeaders/users";

const Users = () => {
	const dataConfig = {
		title: "Usuarios",
		module: "users",
		tableHeader: tableHeaders,
		description: "Listado de Usuarios registrado en el sistema",
		endpoint: "listado/usuarios"
	}
	return (
		<SimpleDataTable data={dataConfig} />
	)
}

export default Users;