
export const tableHeaders = [
	{
		"field": "nombre",
		"headerName": "Nombre",
		"headerAlign": "center",
		"align": "center",
		"flex": 2
	},
	{
		"field": "apPat",
		"headerName": "Apellido Paterno",
		"headerAlign": "center",
		"align": "center",
		"flex": 2
	},
	{
		"field": "apMat",
		"headerName": "Apellido Materno",
		"headerAlign": "center",
		"align": "center",
		"flex": 2
	},
	{
		"field": "correo",
		"headerName": "Correo",
		"headerAlign": "center",
		"align": "center",
		"flex": 2
	},
	{
		"field": "telefono1",
		"headerName": "Teléfono",
		"headerAlign": "center",
		"align": "center",
		"flex": 1
	},
	{
		"field": "status",
		"headerName": "Status",
		"headerAlign": "center",
		"align": "center",
		"flex": 1
	},
	{
		"field": "acciones",
		"headerName": "Acciones",
		"headerAlign": "center",
		"align": "center",
		"botons": {
			"editar": {
				"label": "Editar",
				"class": "warning",
				"type": "link",
				"action": "edit/${row.id}"
			},
			"eliminar": {
				"label": "Eliminar",
				"class": "error",
				"type": "link",
				"action": "edit/${row.id}"
			}
		},
		"flex": 1
	}
];
