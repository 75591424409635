import { articlesHeaders, tableData } from "../../data/tableHeaders/articles";
import { GetArticlesList } from "../../components/compounds/compoundList";
import { searchParams } from "../../data/search/articles";

const Inventory = () => {
	// Parámetros de Configuración para cargar los datos requeridos
	// ----------------------------------------------------------------------------------------
	const articlesConfig = {
		id: 1,
		title: "Articulos",
		module: "articles",
		tableHeader: articlesHeaders,
		tableData: tableData,
		description: "Listado de Articulos registrado en el sistema",
		endpoint: "datos/articulosCompletos",
		contentList: "elementos/compuesto"
	}
	// ----------------------------------------------------------------------------------------


	// No Tocar este bloque de código
	// ========================================================================================
		return (
			<GetArticlesList data={articlesConfig} />
		)
	// ========================================================================================
}

export default Inventory;