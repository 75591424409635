import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ExpandLess, ExpandMore, Save, Cancel } from '@mui/icons-material';

import { Grid, Box, useTheme, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Collapse, IconButton, Button, Modal, Switch, Typography, TextField } from '@mui/material';

import { useSnackbar } from 'notistack';

import { DataGrid } from "@mui/x-data-grid";
import { styled } from '@mui/material/styles';

import { tokens } from "../../data/tokens";
import { Header } from "../basics/Header";
import { UseAppConfig } from '../../system/config';
import { getPostData, getDataArray } from '../../system/getData';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBox, faCopy, faPlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

import { FormRcd } from "../catalogs/addCatalogs";

import { CreateSection, EditSection, AddArticles, CreateProject } from "../../components/projects/addElements";

import '../../theme/project.css';

const formatCurrency = (value) => {
    return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
};

export const JSONDataTable = ({ data }) => {
    const navigate = useNavigate();
    const handleEdit = (field) => {
        navigate(`/${data.module}/edit/${field}`);
    };
    return (
        <Grid p={4} container justifyContent="center" className="row-printable">
            <Grid item xs={12} md={12}>
                <Header title={data.title} subtitle={data.description} />
                <Paper elevation={3} className="panel">
                    <Box mt={4}>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {data.tableHeader.map((item, index) => (
                                            <TableCell
                                                key={index}
                                                sx={{ backgroundColor: '#052c65', color: 'white' }}
                                            >
                                                {item.headerName}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.tableContent.map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell
                                                sx={{ backgroundColor: index % 2 === 0 ? '#f1f2f3' : '#ffffff' }}
                                            >
                                                {item.name}
                                            </TableCell>
                                            <TableCell
                                                align="left"
                                                sx={{ backgroundColor: index % 2 === 0 ? '#f1f2f3' : '#ffffff' }}
                                            >
                                                {item.description || 'Sin descripción'}
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                sx={{ backgroundColor: index % 2 === 0 ? '#f1f2f3' : '#ffffff' }}
                                            >
                                                <Button
                                                    variant="contained"
                                                    color="warning"
                                                    size="small"
                                                    sx={{ mr: 1 }}
                                                    onClick={() => handleEdit(item.field)}
                                                >
                                                    Editar
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    );
};

export const SimpleDataTable = ({ data }) => {
    const { config, configData } = UseAppConfig();
    const [dataTable, setDataTable] = useState([]);

    const fetchDataTable = async (params = {}) => {
        try {
            const jsonData = await getPostData(`${config.API_BASE_URL}/${data.endpoint}`, { params });

            let formattedData;
            const formatNumber = data.tableHeader.some(rcd => rcd.currency == 1);

            console.log('Los filtros de busqueda son: ' + params);

            formattedData = jsonData.map(row => ({
                ...row,
                statusValue: row.status,
                status: row.status === 1 ? 'Activado' : 'Desactivado',
                total: formatCurrency(row.total)
            }));

            setDataTable(formattedData);
            console.log(formattedData);
        } catch (error) {
            console.error('Se ha generado un error a la hora de intentar obtener los datos:', error);
        }
    };

    useEffect(() => {
        const savedFilters = localStorage.getItem('searchFilters');
        if (savedFilters) {
            const parsedFilters = JSON.parse(savedFilters);
            fetchDataTable(parsedFilters);
        } else {
            fetchDataTable();
        }
    }, [data.endpoint]);

    return (
        <Grid p={4} container justifyContent="center" className="row-printable">
            <Grid item xs={12} md={12}>
                <Header title={data.title} subtitle={data.description} />
                <Paper elevation={3} className="panel">
                    <Box mt={4}>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {data.tableHeader.map((header, index) => (
                                            <TableCell
                                                key={index}
                                                sx={{
                                                    backgroundColor: '#052c65',
                                                    color: 'white',
                                                    textAlign: header.field === 'total' ? 'right' : (header.align || 'center'),
                                                    flex: header.flex || 1,
                                                }}
                                            >
                                                {header.headerName}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dataTable.map((row, rowIndex) => (
                                        <TableRow key={rowIndex}>
                                            {data.tableHeader.map((header, cellIndex) => (
                                                <TableCell
                                                    key={cellIndex}
                                                    sx={{
                                                        backgroundColor: rowIndex % 2 === 0 ? '#f1f2f3' : '#ffffff',
                                                        textAlign: header.field === 'total' ? 'right' : (header.align || 'center'),
                                                        flex: header.flex || 1,
                                                    }}
                                                >
                                                    {header.field === 'acciones' ? (
                                                        Object.keys(header.botons).map((buttonKey) => {
                                                            const button = header.botons[buttonKey];
                                                            return (
                                                                <Button
                                                                    key={buttonKey}
                                                                    variant="contained"
                                                                    color={button.class}
                                                                    size="small"
                                                                    sx={{ mr: 1 }}
                                                                    onClick={() => {
                                                                        if (button.type === 'link') {
                                                                            window.location.href = data.module + '/' + button.action.replace('${row.id}', row.id);
                                                                        }
                                                                    }}
                                                                >
                                                                    {button.label}
                                                                </Button>
                                                            );
                                                        })
                                                    ) : (
                                                        row[header.field]
                                                    )}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    );
};

export const DataTablePerID = ({ data, section }) => {
    const { id } = useParams();
    const { config } = UseAppConfig();
    const [dataTable, setDataTable] = useState({});

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const [modalOpen, setModalOpen] = useState(false);
    const [modalSectionName, setModalSectionName] = useState('');
    const [modalSectionValue, setModalSectionValue] = useState('');
    const [modalSection, setModalSection] = useState(false);

    const handleModalOpen = (catalogValue, catalogName, section) => {
        setModalSectionValue(catalogValue);
        setModalSectionName(catalogName);
        setModalSection(section);
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const getColumns = (catalog) => {
        if (!catalog || !catalog.columns) return [];
        const columns = [
            ...(catalog.columns || []),
            {
                field: 'edit',
                headerName: 'Editar',
                width: 100,
                headerAlign: 'center',
                align: 'center',
                renderCell: (params) => (
                    <Box display="flex" justifyContent="center" width="100%">
                        <Button
                            style={{ width: '10px', minWidth: '10px' }}
                            variant="contained"
                            color="warning"
                            onClick={() => handleModalOpen(id, params.row.id, 'edit')}
                        >
                            <FontAwesomeIcon icon={faEdit} />
                        </Button>
                    </Box>
                ),
            },
        ];
        return columns;
    };

    const fetchDataTable = async (field) => {
        try {
            const jsonData = await getPostData(`${config.API_BASE_URL}/${data.endpoint}/${field}`, {});

            let formattedData = jsonData.map(row => ({
                ...row,
                status: row.status === 1 ? 'Activo' : 'Desactivado',
                module: row.tipo === 'Simple' ? 'articles' : 'compounds'
            }));

            setDataTable(prevState => ({ ...prevState, [field]: formattedData }));
        } catch (error) {
            console.error('Error al obtener los datos:', error);
            setError(`No ha sido posible obtener los datos del registro: ${field}`);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            if (id) {
                setIsLoading(true);
                await fetchDataTable(id);
                setIsLoading(false);
            }
        };
        fetchData();
    }, [id]);

    if (isLoading) {
        return <div>Cargando...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <Box m="0px">
            <Box
                maxWidth="100%"
                mx="auto"
                mt="-25px"
                sx={{
                    "& .MuiTable-root": { border: "none" },
                    "& .MuiTableCell-root": { borderBottom: "none" },
                    "& .MuiTableHead-root": { backgroundColor: colors.primary[500], borderBottom: "none" },
                    "& .MuiTableCell-head": { color: colors.grey[900] },
                    "& .MuiTableContainer-root": { backgroundColor: colors.primary[900] },
                }}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Typography variant="h6">Registros del Catálogo</Typography>
                    <Button
                        variant="contained"
                        color="info"
                        onClick={() => handleModalOpen(id, section.name, 'new')}
                    >
                        <FontAwesomeIcon icon={faPlus} /> Agregar
                    </Button>
                </Box>
                <DataGrid
                    rows={dataTable[id] || []}
                    columns={getColumns(section) || []}
                    getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0 ? 'fila-par' : 'fila-impar'
                    }
                    disableColumnMenu
                    disableColumnSelector
                    hideFooter
                    className="datos-tabla"
                    autoHeight
                    sx={{
                        '& .MuiDataGrid-columnHeaders': {
                            backgroundColor: '#052c65',
                            color: '#fff',
                        }
                    }}
                />
            </Box>
            <Modal
                open={modalOpen}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Paper
                    elevation={3}
                    sx={{
                        width: '100%',
                        maxWidth: '700px',
                        margin: '0 auto',
                        padding: 2,
                    }}
                >
                    {modalSection === 'new' ? (
                        <FormRcd secValue={modalSectionValue} secName={modalSectionName} />
                    ) : (
                        <FormRcd secValue={modalSectionValue} regID={modalSectionName} />
                    )}
                </Paper>
            </Modal>
        </Box>
    );
};

export const ExpandableDataTable = ({ data }) => {
    const { config } = UseAppConfig();
    const [dataTable, setDataTable] = useState([]);
    const [loading, setLoading] = useState(true);

    const { enqueueSnackbar } = useSnackbar();

    const buildHierarchy = (flatData) => {
        const dataMap = {};
        const result = [];

        flatData.forEach(item => {
            dataMap[item.id] = { ...item, cat: [] };
        });

        flatData.forEach(item => {
            const { id, parent_id } = item;
            if (parent_id === null || parent_id === 'null') {
                result.push(dataMap[id]);
            } else {
                if (dataMap[parent_id]) {
                    dataMap[parent_id].cat.push(dataMap[id]);
                } else {
                    console.warn(`Subcategoría con ID ${id} tiene un parent_id (${parent_id}) que no existe.`);
                }
            }
        });
        return result;
    };

    useEffect(() => {
        const fetchDataTable = async () => {
            try {
                const response = await getDataArray(`${config.API_BASE_URL}/${data.contentList}`, { proyID: data.id });
                console.log("Datos planos recibidos:", response);
                const hierarchicalData = buildHierarchy(response);
                console.log("Datos jerarquizados:", hierarchicalData);
                setDataTable(hierarchicalData);
            } catch (error) {
                console.error('Error fetching categories:', error);
                enqueueSnackbar('Error al cargar las categorías', { variant: 'error' });
            }
        };

        fetchDataTable();
    }, [data.id, data.getData]);

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        {data.tableHeader.map((header, index) => (
                            <TableCell
                                key={index}
                                sx={{
                                    backgroundColor: '#052c65',
                                    color: 'white',
                                    width: header.width,
                                    textAlign: header.field === 'total' ? 'right' : header.align || 'center',
                                }}
                            >
                                {header.headerName}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dataTable.length > 0 ? (
                        dataTable.map((row, index) => (
                            <ExpandableData key={index} data={row} level={0} />
                        ))
                    ) : (
                        <TableRow>
                            <TableCell colSpan={data.tableHeader ? data.tableHeader.length : 1} align="center">
                                No hay datos para mostrar.
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const ExpandableData = ({ data, level = 0 }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const handleToggle = () => {
        setIsExpanded(!isExpanded);
    };

    const IconsDefault = ({ tipo, icon = '' }) => {
        const iconMap = {
            'Categoría': '/assets/icons/openBox.png',
            'Simple': '/assets/icons/barCode.png',
            'Compuesto': '/assets/icons/QR.png',
        };

        const iconSrc = icon.length > 0 ? `/assets/icons/${icon}` : iconMap[tipo];

        return (
            <TableCell sx={{ textAlign: 'center', width: '7%' }}>
                {iconSrc ? (
                    <img src={iconSrc} width="25px" alt="" />
                ) : (
                    tipo || 'Tipo'
                )}
            </TableCell>
        );
    };

    const ColorsDefault = ({ nombre, tipo }) => {
        const styleConfig = {
            'Categoría': { color: 'black', fontSize: '16px', fontWeight: 'bold', textTransform: 'uppercase' },
            'Compuesto': { color: 'green', fontSize: '15px', fontWeight: 'normal' },
            'Simple': { color: 'blue', fontSize: '14px', fontWeight: 'normal' },
        };

        const defaultStyle = { color: 'red', fontSize: '14px', fontWeight: 'normal' };
        const appliedStyle = styleConfig[tipo] || defaultStyle;

        return <span style={appliedStyle}>{nombre}</span>;
    };

    const botonesConfig = {
        'Categoría': [
            { label: 'Agregar Sub-Categoría', color: 'success', icon: faBox },
            { label: 'Agregar Artículos', color: 'info', icon: faCopy },
            { label: 'Editar', color: 'warning', icon: faEdit },
            { label: 'Eliminar', color: 'error', icon: faTrash },
        ],
        'Compuesto': [
            { label: 'Eliminar', color: 'error', icon: faTrash },
        ],
        'Simple': [
            { label: 'Eliminar', color: 'error', icon: faTrash },
        ],
    };

    const commonCellStyle = {
        textAlign: 'center',
        width: '10%', // Ajusta el ancho según tus necesidades
    };

    const botones = botonesConfig[data.tipo] || [];

    return (
        <>
            {data && (
                <TableRow sx={{ '& > *': { padding: '4px 4px' } }}>
                    <TableCell
                        sx={{
                            borderBottom: 'none',
                            position: 'relative',
                            backgroundColor: '#f1f2f3',
                            paddingLeft: `${level * 2}%`,
                            width: '40%',
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {(data.art && data.art.length > 0) || (data.cat && data.cat.length > 0) ? (
                                <IconButton sx={{ marginRight: 1, cursor: 'pointer' }} onClick={handleToggle}>
                                    {isExpanded ? <ExpandLess /> : <ExpandMore />}
                                </IconButton>
                            ) : (
                                <span style={{ marginLeft: '12px', marginRight: '15px', fontSize: '20px' }}>→</span>
                            )}
                            <Typography sx={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}>
                                <Box component="span" sx={{ marginLeft: 1 }}>
                                    <ColorsDefault nombre={data.nombre} tipo={data.tipo} />
                                </Box>
                            </Typography>
                        </Box>
                    </TableCell>

                    <TableCell sx={{ textAlign: 'center', width: '10%' }}>
                        {botones.map((button, index) => (
                            <Button
                                key={index}
                                variant="contained"
                                color={button.color}
                                title={button.label}
                                style={{ minWidth: '25px', margin: '2px' }}
                            >
                                <FontAwesomeIcon icon={button.icon} />
                            </Button>
                        ))}
                    </TableCell>

                    <IconsDefault tipo={data.tipo} icon={data.icono} />
                    {Object.keys(data)
                        .filter((field) =>
                            field !== 'id' &&
                            field !== 'parent_id' &&
                            field !== 'nombre' &&
                            field !== 'tipo' &&
                            field !== 'icono' &&
                            field !== 'cat' &&
                            field !== 'art'
                        )
                        .map((field, index) => (
                            <TableCell
                                key={index}
                                sx={{
                                    ...commonCellStyle,
                                    textAlign: index === 0 ? 'left' : 'center',
                                    width: index === 0 ? '10%' : '10%'
                                }}
                            >
                                {data[field] || field}
                            </TableCell>
                        ))
                    }
                </TableRow>
            )}

            {data.cat && (
                <TableRow>
                    <TableCell style={{ padding: 0, borderBottom: 'none' }} colSpan={10}>
                        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                            <Table size="small">
                                <TableBody>
                                    {data.cat.map((child, index) => (
                                        <ExpandableData key={index} data={child} level={level + 1} />
                                    ))}
                                </TableBody>
                            </Table>
                        </Collapse>
                    </TableCell>
                </TableRow>
            )}

            {data.art && (
                <TableRow>
                    <TableCell style={{ padding: 0, borderBottom: 'none' }} colSpan={10}>
                        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                            <Table size="small">
                                <TableBody>
                                    {data.art.map((child, index) => (
                                        <ExpandableData key={index} data={child} level={level + 1} />
                                    ))}
                                </TableBody>
                            </Table>
                        </Collapse>
                    </TableCell>
                </TableRow>
            )}
        </>
    );
}