import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

const ConfirmationDialog = ({ open, onClose, onConfirm, statusValue, keyValue }) => {
    let dialogTitle, dialogContent, btnColor, confirmButtonText, btnCancelColor, cancelBtnText;
    switch ( statusValue ) {
        case 0:
            dialogTitle = "Activar Artículo";
            dialogContent = "Está seguro de querer activar este artículo?";
            btnCancelColor = "error";
            cancelBtnText = "Cancelar";
            btnColor = "success";
            confirmButtonText = "Activar";
            break;
        case 1:
            dialogTitle = "Desactivar Artículo";
            dialogContent = "Está seguro de querer desactivar este artículo?";
            btnCancelColor = "warning";
            cancelBtnText = "Cancelar";
            btnColor = "warning";
            confirmButtonText = "Desactivar";
            break;
        case 2:
            dialogTitle = `Retirar Artículo ${keyValue}`;
            dialogContent = `Está seguro de querer retirar el artículo ${keyValue} de esta categoría?`;
            btnCancelColor = "warning";
            cancelBtnText = "Cancelar";
            btnColor = "error";
            confirmButtonText = "Retirar";
            break;
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {dialogContent}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={onClose} color={btnCancelColor} autoFocus>
                    {cancelBtnText}
                </Button>
                <Button variant="contained" onClick={onConfirm} color={btnColor} autoFocus>
                    {confirmButtonText}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationDialog;
