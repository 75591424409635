import { useParams } from "react-router-dom";
import CatalogDetails from "../../components/catalogs/catalogDetails";
import { tableContent } from "../../data/catalogList";

const CatalogDetail = () => {
	// Parámetros de Configuración para cargar los datos requeridos
	// ----------------------------------------------------------------------------------------
	const { id } = useParams();
	const catalogsConfig = {
		id: id,
		title: "Catálogos",
		module: "catalogs",
		tableContent: tableContent,
		description: "Listado de Catálogos registrado en el sistema",
		endpoint: "catalogo"
	}
	// ----------------------------------------------------------------------------------------


	// No Tocar este bloque de código
	// ========================================================================================
		return (
			<CatalogDetails data={catalogsConfig} />
		)
	// ========================================================================================
}

export default CatalogDetail;