import React, { useEffect, useState } from 'react';
import { Box, TextField, Container, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, useTheme } from '@mui/material';

import { Formik } from "formik";
import { useSnackbar } from 'notistack';
import { tokens } from "../../data/tokens";
import { useNavigate } from 'react-router-dom';
import { sendPostData, getDataArray } from '../../system/getData';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';

import { Header } from "../basics/Header";
import { UseAppConfig } from '../../system/config';

import AddArticles from "./../compounds/addArticles";
import { ExpandableDataTable } from "../../components/globals/newDataTable";

export const GetArticlesList = ({ data }) => {
	const { config, configData } = UseAppConfig();

	return (
        <Box m="20px">
			<Header title={data.title} subtitle={data.description} />
			<ExpandableDataTable data={ data } />
		</Box>
	)
};

export const GetCompoundAmounts = ({ data, initValues }) => {
	const formatCurrency = (value) => {
		return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
	};

	const navigate = useNavigate();
	const [rows, setRows] = useState([]);
	const { enqueueSnackbar } = useSnackbar();
	const [loading, setLoading] = useState(true);
	const [currentCantidad, setCurrentCantidad] = useState(formatCurrency(initValues.precioCompra));

	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const { config, configData } = UseAppConfig();

	const handleChange = (id, totalGeneral, newValue) => {
		setRows((prevRows) =>
			prevRows.map((row) =>
				row.id === id
					? { ...row, cantidad: newValue, isChanged: true, total: row.precio * newValue, percentage: totalGeneral > 0 ? (row.precio * newValue / totalGeneral) * 100 : 0 }
					: row
			)
		);
	};

	const handleDisabledr = (id, newValue) => {
		setRows((prevRows) =>
			prevRows.map((row) =>
				row.id === id
					? { ...row, isConfirmed: true, isChanged: false }
					: row
			)
		);
	};

	const handleClick = async (id, newValue) => {
		if (newValue && newValue > 0) {
			try {
				const result = await sendPostData(`${config.API_BASE_URL}/edita/compuesto`, { id, cantidad: newValue });
				console.log('Formulario actualizado exitosamente:', result);
				handleDisabledr(id, newValue);
				enqueueSnackbar('Cantidad actualizada exitosamente', { variant: 'success' });
				try {
					const precioVentaResponse = await sendPostData(`${config.API_BASE_URL}/consulta/precioVenta`, { id: initValues.id });
					console.log('El nuevo Precio de Venta es:', JSON.stringify(precioVentaResponse));
					if (Array.isArray(precioVentaResponse) && precioVentaResponse.length > 0) {
						const precioVenta = precioVentaResponse[0];
						console.log('Precio de Compra:', precioVenta.precioCompra);
						setCurrentCantidad(formatCurrency(precioVenta.precioCompra));
					}
				} catch (error) {
					console.error('Error al consultar el nuevo Precio de Venta:', error);
				}
			} catch (error) {
				console.error('Error al actualizar la cantidad de este artículo:', error);
				enqueueSnackbar('Error al Actualizar la Cantidad de este Artículo', { variant: 'error' });
			}
		}
	};

	const fetchDataTable = async () => {
		try {
			const jsonData = await getDataArray(`${config.API_BASE_URL}/${data.sendData}`, { id: initValues.id });
			const totalGeneral = jsonData.reduce((sum, row) => sum + (row.precio * row.cantidad), 0);

			const formatData = jsonData.map(row => {
				const total = row.precio * row.cantidad;
				const percentage = totalGeneral > 0 ? (total / totalGeneral) * 100 : 0;
				return {
					...row,
					status: row.statusElemento == 1 ? 'Activado' : 'Desactivado',
					cantidadAnterior: row.cantidad,
					total: total,
					totalGeneral: totalGeneral,
					percentage: percentage
				};
			});

			setRows(formatData);
			console.log('Detalles del Registro: ' + JSON.stringify(jsonData));
		} catch (error) {
			console.error('Se ha generado un error a la hora de intentar obtener los datos:', error);
			setRows([]);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchDataTable();
	}, [initValues.id, data.sendData]);

	if (loading) {
		return <div>Cargando...</div>;
	}

	const articlesData = {
		padreID: initValues.id
	};

	const handleFormDelete = async (thisID) => {
		enqueueSnackbar('Artículo: ' + thisID + ' Retirado del Compuesto', { variant: 'error' });
	};

	const handleSaleSubmit = async (values) => {
		console.log('Nuevo Precio de Venta: ' + JSON.stringify(values));
		try {
			const result = await sendPostData(`${config.API_BASE_URL}${data.sendData2}`, values);
			console.log('Formulario actualizado exitosamente:', result);
			enqueueSnackbar('Precio de Venta actualizado exitosamente', { variant: 'success' });
			navigate(`/${data.module}`);
		} catch (error) {
			enqueueSnackbar(data.msgError, { variant: 'error' });
		}
	};

	return (
		<Container>
			<Grid container justifyContent="center" className="row-printable">
				<Grid item xs={12} md={12}>
					<Grid container spacing={3} style={{ position: 'relative', right: '-400px' }}>
						<Grid item xs={12} sm={6}>
							<Box className="invoice-items">
								<div className="price-card">
									<div className="price-card-content">
										<h2>Precio de Compra:</h2>
										<p className="price">{currentCantidad}</p>
									</div>
								</div>
								<AddArticles
									data={articlesData}
									initValues={initValues}
								/>
							</Box>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Formik
								initialValues={initValues}
								onSubmit={handleSaleSubmit}
							>
								{({
									values: saleValues,
									handleChange: handleSaleChange,
									handleSubmit: handleSaleFormSubmit,
									isValid: isSaleValid,
									dirty: isSaleDirty
								}) => (
									<form onSubmit={handleSaleFormSubmit}>
										<Box>
											<div style={{ position: 'absolute', top: '25px' }}>
												<div className="price-card">
													<div className="price-card-content">
														<h2 style={{ zIndex: '10000' }}>Precio de Venta:</h2>
														<span style={{ position: 'absolute', marginTop: '40px', marginLeft: '-150px', fontSize: '40px', color: 'blue', textAlign: 'center', zIndex: '10000' }}>$</span>
														<TextField
															variant="filled"
															type="number"
															className="input-number"
															onChange={handleSaleChange}
															value={saleValues['precioVenta']}
															name="precioVenta"
															sx={{
																width: '160px',
																'& .MuiInputBase-input': {
																	marginTop: '-6px',
																	fontSize: '40px',
																	color: 'blue',
																	backgroundColor: 'ghostwhite',
																	textAlign: 'center'
																},
															}}
														/>
													</div>
												</div>
												<div style={{ position: 'absolute' }}>
													<Button
														type="submit"
														color={data.colorBtn2}
														width="100%"
														variant="contained"
														style={{ marginTop: '-200px', marginRight: '-280px' }}
														disabled={!isSaleDirty || !isSaleValid}
													>
														{data.titleBtn2}
													</Button>
												</div>
											</div>
										</Box>
									</form>
								)}
							</Formik>
						</Grid>
					</Grid>

					<Paper elevation={3} className="panel">
						<Box className="invoice-items">
							<br /><br />
							<strong className='artCompTitle'>Este Compuesto está conformado por los siguientes Artículos:</strong>
							<br /><br />
							Tenga en cuenta que al modificar las cantidades tendrá que guardar cada registro de manera independiente
							<br /><br />
							<TableContainer>
								<Table>
									<TableHead>
										<TableRow style={{ backgroundColor: colors.grey[900] }}>
											<TableCell className="per5 text-center"><b>Hijo</b></TableCell>
											<TableCell className="per5 text-center"><b>Tipo</b></TableCell>
											<TableCell className="per25 text-center"><b>Precio</b></TableCell>
											<TableCell className="per25 text-center"><b>Descripción</b></TableCell>
											<TableCell className="per25 text-center"><b>Familia</b></TableCell>
											<TableCell className="per5 text-center"><b>Cantidad</b></TableCell>
											<TableCell className="per5 text-center"><b>Total</b></TableCell>
											<TableCell className="per25 text-center"><b>Porcentaje</b></TableCell>
											<TableCell className="per25 text-center"><b>Estado</b></TableCell>
											<TableCell className="per5 text-center"><b>Acciones</b></TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{rows.map((row) => (
											<TableRow key={row.id}>
												<TableCell className="text-center">
													<Button
														variant="contained"
														color={row.tipo === 'Compuesto' ? 'info' : 'inherit'}
														style={{ width: '70px' }}
													>
														{row.hijo}
													</Button>
												</TableCell>
												<TableCell className="text-center">{row.nombreTipo}</TableCell>
												<TableCell className="text-center">{formatCurrency(row.precio)}</TableCell>
												<TableCell className="text-center">{row.descripcion}</TableCell>
												<TableCell className="text-center">{row.nombreFamilia}</TableCell>
												<TableCell className="text-center">
													<input
														type="number"
														value={row.cantidad}
														style={{ width: '60px' }}
														className="input-number"
														onChange={(e) => handleChange(row.id, row.totalGeneral, e.target.value)}
														disabled={!row.statusElemento || row.statusElemento === 'valor_especifico'}
													/>
												</TableCell>
												<TableCell className={`text-center ${row.isConfirmed ? 'green-text' : row.isChanged ? 'yellow-text' : ''}`}>
													{formatCurrency(row.total)}
												</TableCell>
												<TableCell className={`text-center ${row.isConfirmed ? 'green-text' : row.isChanged ? 'yellow-text' : ''}`}>
													{row.percentage.toFixed(2)}%
												</TableCell>
												<TableCell className="text-center">{row.status}</TableCell>
												<TableCell className="text-center">
													<Box sx={{ display: 'flex', gap: '3px' }}>
														<Button
															type="submit"
															color="inherit"
															variant="contained"
															sx={{
																minWidth: 'auto',
																padding: '5px 10px',
																backgroundColor: row.isChanged ? 'green' : undefined,
																'&:disabled': {
																	backgroundColor: 'grey',
																},
															}}
															disabled={!row.isChanged}
															onClick={() => handleClick(row.id, row.cantidad)}
														>
															<FontAwesomeIcon icon={faEdit} />
														</Button>
														<Button
															type="submit"
															color="error"
															variant="contained"
															sx={{ minWidth: 'auto', padding: '5px 10px' }}
															onClick={() => handleFormDelete(row.id)}
														>
															<FontAwesomeIcon icon={faTrash} />
														</Button>
													</Box>
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</Box>
					</Paper>
				</Grid>
			</Grid>
		</Container>
	);
};