
import React, { useEffect, useState } from 'react';
import { Box, useTheme, Container, Grid, Typography, Paper, Modal, Button } from '@mui/material';
import { useParams } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faDollarSign, faFileInvoice, faBoxes, faLocationArrow, faBalanceScale, faUsers, faReceipt, faWarehouse } from '@fortawesome/free-solid-svg-icons';

import { useNavigate } from 'react-router-dom';
import { DataTablePerID } from "../../components/globals/newDataTable";

const iconMap = {
	costos: faDollarSign,
	cuenta: faBalanceScale,
	familia: faBoxes,
	ubicacion: faLocationArrow,
	unidad: faWarehouse,
	tipo: faReceipt,
	facturas: faFileInvoice,
	movimientos: faBoxes,
	polizas: faReceipt,
	usuarios: faUsers
};

const CatalogDetails = ({ data }) => {
	const navigate = useNavigate();
	const handleGoBack = () => {
		navigate(-1);
	};

	const selectedCatalog = data && data.tableContent ? data.tableContent.find(catalog => catalog.field === data.id) : null;

	return (
		<Grid p={4} container justifyContent="center" className="row-printable">
			<Grid item xs={12} md={12}>
				<Grid container justifyContent="center" spacing={3} sx={{ width: '100%' }}>
					<Grid item xs={12} md={12}>
						<Box display="flex" justifyContent="space-between" alignItems="center">
							<Typography variant="h2" align="left" fontWeight="bold" gutterBottom>
								{selectedCatalog ? selectedCatalog.name : 'Catálogo'}
							</Typography>

							<Button
								variant="contained"
								color="success"
								onClick={handleGoBack}
							>
								Regresar
							</Button>
						</Box>
					</Grid>
					<Grid item xs={12} md={12}>
						<Paper elevation={3} sx={{ p: 2, borderBottom: 'none', width: '100%' }}>
							<Box display="flex" flexDirection="row" alignItems="center" style={{ marginBottom: '70px', color: '#1976d2' }}>
								<FontAwesomeIcon icon={iconMap[data.id]} size="6x" style={{ marginRight: '20px', color: '#1976d2' }} />
								<Typography variant="h6" align="left" fontWeight="500" sx={{ fontSize: '1.2rem' }}>
									{selectedCatalog ? selectedCatalog.description : 'Descripción del catálogo.'}
								</Typography>
							</Box>
							<DataTablePerID data={data} section={selectedCatalog} />
						</Paper>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default CatalogDetails;